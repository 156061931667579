
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  PropType,
  computed,
} from "vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import TemplateViewerAddPopup from "@/components/popups/templates/TemplateSignerAddPopup.vue";
import SignerEditPopup from "@/components/popups/contract/SignerEditPopup.vue";
import FilePreview from "@/components/parts/FilePreview.vue";
import { formatLocalDate } from "@/lib/utility/common";
import Signer from "@/models/Signer";
import {
  getFilePathFromUrl,
  formatThounsandNumber,
} from "@/lib/utility/stringUtil";
import { TemplateInformation as ContractDetailInfo } from "@/models/contracts/Information";
import { FileInfo } from "@/models/contracts/FileInfo";
import { SecuritiesInfo } from "@/models/TemplateAndContract";
import UserInListItem from "@/components/atomics/UserInListItem.vue";
import { ContractViewer } from "@/models/contracts/ContractViewer";

interface ContractBasicInfo {
  title: string;
  contractPartnerName: string;
}

export default defineComponent({
  name: "TemplateSummary",
  components: {
    TextButton,
    FlatButton,
    //ToggleCheckbox,
    TemplateViewerAddPopup,
    UserInListItem,
    PdfViewer,
    FilePreview,
    SignerEditPopup,
  },
  emits: ["update:signers", "update:viewers", "update:securitiesInfo"],
  props: {
    files: {
      type: Array as PropType<Array<FileInfo>>,
      required: true,
    },
    signers: {
      type: Array as PropType<Array<Signer>>,
      required: true,
    },
    viewers: {
      type: Array as PropType<Array<ContractViewer>>,
      required: true,
    },
    contractBasicInfo: {
      type: Object as PropType<ContractBasicInfo>,
      required: true,
    },
    contractDetailInfo: {
      type: Object as PropType<ContractDetailInfo>,
      required: true,
    },
    securitiesInfo: {
      type: Object as PropType<SecuritiesInfo>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isMobile = inject("isMobile");

    const state = reactive({
      isShowAddViewersPopup: false,
      isShowAdvanced: true,
      selectedFileIndex: 0,
      viewerEditIndex: -1,
    });

    const localSigners = computed({
      get(): Signer[] {
        return props.signers;
      },

      set(value: Signer[]) {
        emit("update:signers", value);
      },
    });

    const localViewers = computed({
      get(): ContractViewer[] {
        return props.viewers;
      },

      set(value: ContractViewer[]) {
        emit("update:viewers", value);
      },
    });

    const localSecuritiesInfo = computed({
      get(): SecuritiesInfo {
        return props.securitiesInfo;
      },

      set(value: SecuritiesInfo) {
        emit("update:securitiesInfo", value);
      },
    });

    const downloadFile = (file: any) => {
      const link = document.createElement("a");
      link.href = file.path;
      link.target = "_blank";
      link.click();
    };

    const onAddViewer = (payload: ContractViewer[]) => {
      localViewers.value = payload;
      state.isShowAddViewersPopup = false;
    };
    const removeViewer = (index: number) => {
      if (index >= 0) localViewers.value.splice(index, 1);
    };
    const removeSigner = (index: number) => {
      if (index >= 0) localSigners.value.splice(index, 1);
    };

    const setSingleViewer = (viewerInformation: any) => {
      const viewers = Array.from(localViewers.value);
      if (state.viewerEditIndex >= 0)
        viewers[state.viewerEditIndex] = viewerInformation;
      localViewers.value = viewers;

      state.viewerEditIndex = -1;
    };

    return {
      ...toRefs(state),
      ...props.contractBasicInfo,
      localContractDetailInfo: props.contractDetailInfo,
      isMobile,
      localViewers,
      localSigners,
      localFiles: props.files,
      localSecuritiesInfo,
      getFilePathFromUrl,
      formatLocalDate,
      removeSigner,
      removeViewer,
      onAddViewer,
      formatThounsandNumber,
      downloadFile,
      setSingleViewer,
    };
  },
});
