import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46291570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "row-info" }
const _hoisted_3 = {
  key: 0,
  class: "order-number"
}
const _hoisted_4 = { class: "number" }
const _hoisted_5 = { class: "user-info ml-1" }
const _hoisted_6 = { class: "username-row" }
const _hoisted_7 = { class: "username" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "email"
}
const _hoisted_10 = {
  key: 3,
  class: "company ml-1"
}
const _hoisted_11 = { class: "row-button" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.order)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.order), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.profilePicture)
        ? (_openBlock(), _createBlock(_component_AvatarCircle, {
            key: 1,
            class: "ml-1 avatar",
            avatar: _ctx.getFilePathFromUrl(_ctx.user.profilePicture),
            alt: "avatar"
          }, null, 8, ["avatar"]))
        : (_openBlock(), _createBlock(_component_AvatarCircle, {
            key: 2,
            class: "ml-1 avatar",
            avatar: require('icons/default-avatar.svg'),
            alt: "avatar"
          }, null, 8, ["avatar"])),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.user.placeholder || _ctx.user.lastName + " " + _ctx.user.firstName), 1),
          (_ctx.user.accessCode)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "icon-key ml-1",
                src: require('icons/key.svg'),
                alt: "icon key"
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Vue3Popper, {
          content: _ctx.user.email
        }, {
          default: _withCtx(() => [
            (!_ctx.user.placeholder)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.user.email), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["content"])
      ]),
      (_ctx.user.companyName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.user.companyName), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.canEdit)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editUser'))),
            src: require('icons/edit.svg'),
            alt: "icon edit",
            class: "icon-edit"
          }, null, 8, _hoisted_12))
        : _createCommentVNode("", true),
      _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('removeUser'))),
        src: require('icons/close-red-circle.svg'),
        alt: "icon delete",
        class: "icon-delete"
      }, null, 8, _hoisted_13)
    ])
  ]))
}