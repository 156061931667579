
import { defineComponent, PropType } from "vue";
import User from "@/models/Signer";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "UserInListItem",
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    order: {
      type: Number,
      default: 0,
    },
    canEdit: { type: Boolean, default: true },
  },
  emits: ["removeUser", "editUser"],
  components: {
    AvatarCircle,
    Vue3Popper,
  },
  setup() {
    return {
      getFilePathFromUrl,
    };
  },
});
